import * as React from 'react';

import { EditButton, SelectField, TextField, useTranslate } from 'react-admin';

import AvatarField from './AvatarField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
// import DisscountReferenceField from './DiscountReferenceField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: { margin: '1em' },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem 0',
  },
  cardTitleContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardContent: {
    ...theme.typography.body1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const MobileGrid = ({ ids, data, basePath }) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (!ids || !data) {
    return null;
  }

  return (
    <div className={classes.root}>
      {ids.map((id) => (
        <Card key={id} className={classes.card}>
          <CardHeader
            title={
              <div className={classes.cardTitleContent}>
                <h2>{`${data[id].name} (${data[id].companyName})`}</h2>
                <EditButton
                  resource="customers"
                  basePath={basePath}
                  record={data[id]}
                />
              </div>
            }
            avatar={<AvatarField record={data[id]} size="45" />}
          />
          <CardContent className={classes.cardContent}>
            <div>
              {translate('resources.customers.fields.phone_number')}:&nbsp;
              <TextField record={data[id]} source="phone" />
            </div>
            <div>
              {translate('resources.customers.fields.discount')}:&nbsp;
              <SelectField
                record={data[id]}
                source="role"
                choices={[
                  { id: 'admin', name: 'Админ' },
                  { id: 'user', name: 'Розница' },
                  { id: 'opt', name: 'Опт' },
                ]}
              />
              {/* <TextField record={data[id]} source="role" /> */}
              {/* <DisscountReferenceField
                record={data[id]}
                basePath={'/discounts'}
              /> */}
            </div>
            {/* <div>
              {translate('resources.commands.name', data[id].nb_commands || 1)}
              &nbsp;:&nbsp;
              <NumberField
                record={data[id]}
                source="nb_commands"
                label="resources.customers.fields.commands"
              />
            </div> */}
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

MobileGrid.defaultProps = {
  data: {},
  ids: [],
};

export default MobileGrid;
