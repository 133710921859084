import {
  BooleanInput,
  Datagrid,
  Edit,
  Filter,
  FormDataConsumer,
  Labeled,
  List,
  PasswordInput,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  minLength,
  required,
} from 'react-admin';

import PhoneField from './customers/PhoneField';
import React from 'react';

const validatePassword = [minLength(6)];

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
  </Filter>
);

export const UserList = (props) => (
  <List
    {...props}
    title={props.options.label}
    sort={{ field: 'signUpDate', order: 'DESC' }}
    filters={<UserFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Имя" />
      <TextField source="email" label="Email" />
      <PhoneField source="phone" label="Телефон" />
      <SelectField
        label="Роль"
        source="role"
        choices={[
          { id: 'admin', name: 'Администратор' },
          { id: 'manager', name: 'Менеджер' },
        ]}
      />
    </Datagrid>
  </List>
);

const UserTitle = ({ record }) => {
  return <span>Пользователь {record ? `"${record.name}"` : ''}</span>;
};

export const UserCreate = (props) => (
  <Edit title={<UserTitle />} {...props}>
    <SimpleForm>
      <TextInput label="Имя" source="name" validate={[required()]} />
      <TextInput label="Email" source="email" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <SelectInput
              // disabled={formData.role === 'admin'}
              label="Роль"
              source="role"
              choices={[
                { id: 'admin', name: 'Администратор' },
                { id: 'manager', name: 'Менеджер' },
                { id: 'user', name: 'Пользователь' },
              ]}
              validate={[required()]}
            />
          );
        }}
      </FormDataConsumer>
      <TextInput
        label="Номер телефона"
        source="phone"
        validate={[required()]}
        parse={(v) => v.replace(/[^\d]/, '')}
        inputProps={{
          maxLength: 10,
        }}
      />
      <BooleanInput label="Изменить пароль" source="chPass" />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) =>
          formData.chPass && (
            <Labeled
              label={`Введите новый пароль, затем отправьте его пользователю вручную. Пароль будет доступен для просмотра только 1 раз`}
            >
              <PasswordInput
                label="Введите новый пароль"
                source="password"
                initiallyVisible
                disabled={!formData.chPass}
                validate={validatePassword}
              />
            </Labeled>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export const UserEdit = (props) => (
  <Edit title={<UserTitle />} {...props}>
    <SimpleForm>
      <TextInput label="Имя" source="name" validate={[required()]} />
      <TextInput label="Email" source="email" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <SelectInput
              // disabled={formData.role === 'admin'}
              label="Роль"
              source="role"
              choices={[
                { id: 'admin', name: 'Администратор' },
                { id: 'manager', name: 'Менеджер' },
                { id: 'user', name: 'Пользователь' },
              ]}
              validate={[required()]}
            />
          );
        }}
      </FormDataConsumer>
      <TextInput
        label="Номер телефона"
        source="phone"
        validate={[required()]}
        parse={(v) => v.replace(/[^\d]/, '')}
        inputProps={{
          maxLength: 10,
        }}
      />
      <BooleanInput label="Изменить пароль" source="chPass" />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) =>
          formData.chPass && (
            <Labeled
              label={`Введите новый пароль, затем отправьте его пользователю вручную. Пароль будет доступен для просмотра только 1 раз`}
            >
              <PasswordInput
                label="Введите новый пароль"
                source="password"
                initiallyVisible
                disabled={!formData.chPass}
                validate={validatePassword}
              />
            </Labeled>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
