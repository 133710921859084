import {
  ChipField,
  Create,
  Datagrid,
  Edit,
  Filter,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

import { InputAdornment } from '@material-ui/core';
import React from 'react';

const ItemsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
    <ReferenceInput
      label="Производитель"
      source="manufacturer_q"
      reference="heater-manufacturers"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    {props.filterValues.manufacturer_q && (
      <ReferenceInput
        label="Категория"
        source="category_q"
        reference="heater-categories"
        perPage={200}
        filter={{ manufacturer_q: props.filterValues.manufacturer_q }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    )}
  </Filter>
);

export const HeaterItemsList = (props) => (
  <List filters={<ItemsFilter />} {...props} title={props.options.label}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Название товара" />
      <ReferenceField
        label="Категория"
        source="category"
        reference="heater-categories"
      >
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Производитель"
        source="manufacturer"
        reference="heater-manufacturers"
      >
        <TextField source="name" />
      </ReferenceField>
      {/* <SelectField
        source="typeCat"
        label="Тип"
        choices={[
          { id: 'common', name: 'Бытовые кондиционеры' },
          { id: 'multi', name: 'Мульти-сплит системы' },
          { id: 'commercial', name: 'Коммерческие кондиционеры' },
        ]}
      /> */}
      <TextField source="optPrice" label="Цена, опт." />
      <TextField source="price" label="Цена, розн." />
      <TextField source="order" label="Порядок" />
    </Datagrid>
  </List>
);

const ItemsTitle = ({ record }) => {
  return <span>Товар {record ? `"${record.name}"` : ''}</span>;
};

export const HeaterItemsEdit = (props) => (
  <Edit title={<ItemsTitle />} {...props}>
    <TabbedForm>
      <FormTab label={'resources.items.tabs.main'}>
        <ReferenceInput
          label="Производитель"
          source="manufacturer"
          reference="heater-manufacturers"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.manufacturer ? (
              <ReferenceInput
                label="Категория"
                source="category"
                reference="heater-categories"
                perPage={100}
                filter={{ manufacturer_q: formData.manufacturer }}
                validate={[required()]}
                {...rest}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>
        <TextInput
          source="name"
          label="Название товара"
          validate={[required()]}
        />
        <NumberInput
          source="optPrice"
          label="Цена, опт."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NumberInput
          source="price"
          label="Цена, розн."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NumberInput
          source="order"
          label="Порядок"
          min={1}
          max={99}
          step={1}
          validate={[required()]}
        />
      </FormTab>
      <FormTab label="resources.items.tabs.details" path="details">
        <TextInput multiline fullWidth source="description" label="Описание" />
      </FormTab>
      <FormTab label="resources.items.tabs.photo" path="photo">
        <ImageField source="image" label="Текущее Фото" />
        <ImageInput
          source="image"
          label="Новое Фото"
          accept="image/*"
          multiple={false}
        >
          <ImageField source="image" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const HeaterItemsCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label={'resources.items.tabs.main'}>
        <ReferenceInput
          label="Производитель"
          source="manufacturer"
          reference="heater-manufacturers"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceInput
              label="Категория"
              source="category"
              reference="heater-categories"
              perPage={100}
              filter={{ manufacturer_q: formData.manufacturer }}
              validate={[required()]}
              {...rest}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <TextInput
          source="name"
          label="Название товара"
          validate={[required()]}
        />
        <NumberInput
          source="optPrice"
          label="Цена, опт."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NumberInput
          source="price"
          label="Цена, розн."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NumberInput
          source="order"
          label="Порядок"
          min={1}
          max={99}
          step={1}
          initialValue={99}
          validate={[required()]}
        />
      </FormTab>
      <FormTab label="resources.items.tabs.details" path="details">
        <TextInput multiline fullWidth source="description" label="Описание" />
      </FormTab>
      <FormTab label="resources.items.tabs.photo" path="photo">
        <ImageInput
          source="image"
          label="Фото"
          accept="image/*"
          multiple={false}
        >
          <ImageField source="image" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
