import russianMessages from 'ra-language-russian';

const customRussianMessages = {
  ...russianMessages,
  pos: {
    configuration: 'Настройки',
    phone: 'Номер телефона',
    language: 'Язык',
    configure_users: 'Управление',
    users_link: 'Пользователи',
    theme: {
      name: 'Тема',
      light: 'Светлая',
      dark: 'Темная',
    },
    dashboard: {
      exchange_rate: 'Курс валют',
      items_count: 'Кол-во товаров',
      new_users: 'Новые пользователи',
    },
    menu: {
      users: 'Пользователи',
      customers: 'Клиенты',
      rates: 'Курс Валют',
      brands: 'Бренды',
      manufacturers: 'Производители',
      categories: 'Категории',
      items: 'Товары',
      additional_items: 'Доп. товары',
      photos: 'Фото',
      errorcodes: 'Коды ошибок',
      catalogsPdf: 'Каталоги PDF',
      articles: 'Статьи',
      conditioners: 'Прайс кондиционеры',
      ventilations: 'Прайс вентиляция',
      heaters: 'Прайс микроклимат',
      tool: 'Прайс отопление',
      consumables: 'Прайс матер. и инстр.',
      notifications: 'Push уведомления',
      orders: 'Заказы',
    },
  },
  resources: {
    items: {
      tabs: {
        main: 'Основное',
        details: 'Детали',
        photo: 'Фото',
      },
    },
    categories: {
      fields: {
        items: 'Товары',
      },
    },
    customers: {
      name: 'Клиент',
      fields: {
        name: 'Клиент',
        phone_number: 'Номер телефона',
        discount: 'Категория цен',
      },
    },
    rates: {
      name: 'Валюты',
      fields: {
        name: 'Валюта',
        cashRate: 'Нал',
        cashlessRate: 'Безнал',
        date: 'Дата изменения',
      },
    },
    error_codes: {
      name: 'Коды ошибок',
      fields: {
        name: 'Код ошибки',
        description: 'Описание',
      },
    },
    notifications: {
      name: 'Push Уведомления',
      fields: {
        name: 'Уведомление',
        date: 'Дата',
      },
    },
    commands: {
      name: 'Заказы',
      title: 'Заказы',
      section: {
        order: 'Заказ',
        customer: 'Клиент',
        shipping_address: 'Адрес доставки',
        items: 'Товары',
        totals: 'Итоги',
        total: 'Итог',
      },
      fields: {
        nb_items: 'К-во',
        items: {
          manufacturer: 'Производитель',
          reference: 'Наименование',
          unit_price_usd: 'Цена, $',
          unit_price: 'Цена, грн',
          rate: 'Курс',
          quantity: 'Кол-во',
          summ: 'Сумма, грн',
          total: 'Всего',
          sum: 'Сумма',
          delivery: 'Доставка',
          taxes: 'Налог',
        },
        status: 'Статус',
        returned: 'Возврат',
        date: 'Дата',
        ordered: 'Заказан',
        delivered: 'Доставлен',
        cancelled: 'Отменен',
      },
    },
    brands: {
      name: 'Бренды',
    },
    photos: {
      name: 'Фото',
    },
    articles: {
      name: 'Статьи',
    },
    errorcodes: {
      name: 'Коды ошибок',
    },
    'vent-manufacturers': {
      name: 'Производители "Вентиляция"',
    },
    'vent-categories': {
      name: 'Категории "Вентиляция"',
    },
    'vent-items': {
      name: 'Товары "Вентиляция"',
    },
    'heater-manufacturers': {
      name: 'Производители "Микроклимат"',
    },
    'heater-categories': {
      name: 'Категории "Микроклимат"',
    },
    'heater-items': {
      name: 'Товары "Микроклимат"',
    },
    'tool-manufacturers': {
      name: 'Производители "Отопление"',
    },
    'tool-categories': {
      name: 'Категории "Отопление"',
    },
    'tool-items': {
      name: 'Товары "Отопление"',
    },
    'consumables-manufacturers': {
      name: 'Производители "Матер. и инстр."',
    },
    'consumables-categories': {
      name: 'Категории "Матер. и инстр."',
    },
    'consumables-items': {
      name: 'Товары "Матер. и инстр."',
    },
    'additional-items': {
      name: 'Доп. товары',
    },
  },
  ra: {
    ...russianMessages.ra,
    navigation: {
      ...russianMessages.ra.navigation,
      skip_nav: 'Перейти к содержанию',
    },
  },
};

export default customRussianMessages;
