import React from 'react';

const Logo = (props) => (
  <svg
    width="5em"
    height="3em"
    viewBox="0 0 356 76"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{'KIT'}</title>
    <text
      fill="#EF1A26"
      fillRule="evenodd"
      fontFamily="GoudyStout, Goudy Stout"
      fontSize={96}
      letterSpacing={2.5}
    >
      <tspan x={-6.586} y={73}>
        {'KIT'}
      </tspan>
    </text>
  </svg>
);

export default Logo;
