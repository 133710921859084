import {
  ChipField,
  Create,
  Datagrid,
  Edit,
  Filter,
  FormDataConsumer,
  FormTab,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

import Divider from '@material-ui/core/Divider';
import { InputAdornment } from '@material-ui/core';
import React from 'react';

const ItemsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
    <ReferenceInput
      label="Производитель"
      source="manufacturer_q"
      reference="manufacturers"
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    {props.filterValues.manufacturer_q && (
      <ReferenceInput
        label="Категория"
        source="category_q"
        reference="categories"
        perPage={200}
        filter={{ manufacturer_q: props.filterValues.manufacturer_q }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    )}
    <SelectInput
      source="type_q"
      label="Тип"
      allowEmpty={false}
      choices={[
        { id: 'common', name: 'Бытовые кондиционеры' },
        { id: 'multi', name: 'Мульти-сплит системы' },
        { id: 'commercial', name: 'Коммерческие кондиционеры' },
      ]}
    />
  </Filter>
);

export const ItemsList = (props) => (
  <List filters={<ItemsFilter />} {...props} title={props.options.label}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Название товара" />
      <ReferenceField
        label="Категория"
        source="category"
        reference="categories"
      >
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Производитель"
        source="manufacturer"
        reference="manufacturers"
      >
        <TextField source="name" />
      </ReferenceField>
      <SelectField
        source="typeCat"
        label="Тип"
        choices={[
          { id: 'common', name: 'Бытовые кондиционеры' },
          { id: 'multi', name: 'Мульти-сплит системы' },
          { id: 'commercial', name: 'Коммерческие кондиционеры' },
        ]}
      />
      <TextField source="optPrice" label="Цена, опт." />
      <TextField source="price" label="Цена, розн." />
      <TextField source="stock1" label="Одесса" />
      <TextField source="stock2" label="Украина" />
      <TextField source="order" label="Порядок" />
    </Datagrid>
  </List>
);

const ItemsTitle = ({ record }) => {
  return <span>Товар {record ? `"${record.name}"` : ''}</span>;
};

export const ItemsEdit = (props) => (
  <Edit title={<ItemsTitle />} {...props}>
    <TabbedForm>
      <FormTab label={'resources.items.tabs.main'}>
        <ReferenceInput
          label="Производитель"
          source="manufacturer"
          reference="manufacturers"
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput
          source="typeCat"
          label="Тип"
          choices={[
            { id: 'common', name: 'Бытовые кондиционеры' },
            { id: 'multi', name: 'Мульти-сплит системы' },
            { id: 'commercial', name: 'Коммерческие кондиционеры' },
          ]}
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.manufacturer ? (
              <ReferenceInput
                label="Категория"
                source="category"
                reference="categories"
                perPage={100}
                filter={{ manufacturer_q: formData.manufacturer }}
                validate={[required()]}
                {...rest}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>
        <TextInput
          source="name"
          label="Название товара"
          validate={[required()]}
        />
        <NumberInput
          source="optPrice"
          label="Цена, опт."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NumberInput
          source="price"
          label="Цена, розн."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NumberInput
          source="order"
          label="Порядок"
          min={1}
          max={99}
          step={1}
          validate={[required()]}
        />
        <Divider />
        <p>Склад</p>
        <NumberInput
          source="stock1"
          label="Наличие Одесса"
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
          min={0}
          step={1}
          initialValue={0}
          validate={[required()]}
        />
        <NumberInput
          source="stock2"
          label="Наличие Украина"
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
          min={0}
          step={1}
          initialValue={0}
          validate={[required()]}
        />
      </FormTab>
      <FormTab label="resources.items.tabs.details" path="details">
        <TextInput
          fullWidth
          source="power"
          label="Мощность охл. / обогр. (кВт)"
        />
        <TextInput
          fullWidth
          source="powerСonsumption"
          label="Потребляемая мощность охл. / обогр. (кВт)"
        />
        <TextInput
          fullWidth
          source="temp"
          label="Диапазон внешн. температур (°С)"
        />
        <TextInput fullWidth source="mark" label="Тип компрессора" />
        <TextInput
          fullWidth
          source="dimensions"
          label="Габариты вн. / нар. блок (мм)"
        />
        <TextInput
          fullWidth
          source="connectionWires"
          label="Межблочный кабель (мм²)"
        />
        <TextInput
          fullWidth
          source="connectionPipelines"
          label="Диаметр труб (мм)"
        />
      </FormTab>
      <FormTab label="resources.items.tabs.photo" path="photo">
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.manufacturer ? (
              <>
                <ReferenceInput
                  label="Серия"
                  source="photo"
                  reference="photos"
                  filter={{ manufacturer_q: formData.manufacturer }}
                  {...rest}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                {/* <ReferenceField label="Фото" source="photo" reference="photos">
                  <ImageField source="image" label="Текущее Фото" />
                </ReferenceField> */}
              </>
            ) : null
          }
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const ItemsCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label={'resources.items.tabs.main'}>
          <ReferenceInput
            label="Производитель"
            source="manufacturer"
            reference="manufacturers"
            validate={[required()]}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <SelectInput
            source="typeCat"
            label="Тип"
            choices={[
              { id: 'common', name: 'Бытовые кондиционеры' },
              { id: 'multi', name: 'Мульти-сплит системы' },
              { id: 'commercial', name: 'Коммерческие кондиционеры' },
            ]}
            validate={[required()]}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ReferenceInput
                label="Категория"
                source="category"
                reference="categories"
                perPage={100}
                filter={{ manufacturer_q: formData.manufacturer }}
                validate={[required()]}
                {...rest}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <TextInput
            source="name"
            label="Название товара"
            validate={[required()]}
          />
          <NumberInput
            source="optPrice"
            label="Цена, опт."
            format={(v) => parseFloat(v)}
            parse={(v) => v.replace(/,/g, '.').toString()}
            initialValue={0}
            validate={[required()]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="price"
            label="Цена, розн."
            format={(v) => parseFloat(v)}
            parse={(v) => v.replace(/,/g, '.').toString()}
            initialValue={0}
            validate={[required()]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="order"
            label="Порядок"
            min={1}
            max={99}
            step={1}
            initialValue={99}
            validate={[required()]}
          />
          <Divider />
          <p>Склад</p>
          <NumberInput
            source="stock1"
            label="Наличие Одесса"
            format={(v) => parseFloat(v)}
            parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
            min={0}
            step={1}
            initialValue={0}
            validate={[required()]}
          />
          <NumberInput
            source="stock2"
            label="Наличие Украина"
            format={(v) => parseFloat(v)}
            parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
            min={0}
            step={1}
            initialValue={0}
            validate={[required()]}
          />
        </FormTab>
        <FormTab label="resources.items.tabs.details" path="details">
          <TextInput fullWidth source="power" label="Мощность охл. / обогр." />
          <TextInput
            fullWidth
            source="powerСonsumption"
            label="Потребляемая мощность охл. / обогр. (кВт)"
          />
          <TextInput
            fullWidth
            source="temp"
            label="Диапазон внешн. температур"
          />
          <TextInput fullWidth source="mark" label="Марка компрессора" />
          <TextInput
            fullWidth
            source="dimensions"
            label="Габариты вн. / нар. блок"
          />
          <TextInput
            fullWidth
            source="connectionWires"
            label="Подключение проводов"
          />
          <TextInput
            fullWidth
            source="connectionPipelines"
            label="Подключение трубопроводов"
          />
        </FormTab>
        <FormTab label="resources.items.tabs.photo" path="photo">
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.manufacturer ? (
                <>
                  <ReferenceInput
                    label="Серия"
                    source="photo"
                    reference="photos"
                    filter={{ manufacturer_q: formData.manufacturer }}
                    {...rest}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  {/* <ReferenceField label="Фото" source="photo" reference="photos">
                  <ImageField source="image" label="Текущее Фото" />
                </ReferenceField> */}
                </>
              ) : null
            }
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
