import {
  Create,
  Datagrid,
  Edit,
  Filter,
  FormTab,
  ImageField,
  ImageInput,
  List,
  NumberInput,
  TabbedForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

import Divider from '@material-ui/core/Divider';
import { InputAdornment } from '@material-ui/core';
import React from 'react';

const AdditionalItemsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
  </Filter>
);

export const AdditionalItemsList = (props) => (
  <List
    filters={<AdditionalItemsFilter />}
    {...props}
    title={props.options.label}
  >
    <Datagrid rowClick="edit">
      <TextField source="manufacturer" label="Производитель" />
      <TextField source="category" label="Категория" />
      <TextField source="name" label="Наименование" />
      <TextField source="optPrice" label="Цена, опт." />
      <TextField source="price" label="Цена, розн." />
      <TextField source="stock1" label="Одесса" />
      <TextField source="stock2" label="Украина" />
      <TextField source="order" label="Порядок" />
    </Datagrid>
  </List>
);

const AdditionalItemsTitle = ({ record }) => {
  return <span>Дополнительный товар {record ? `"${record.name}"` : ''}</span>;
};

export const AdditionalItemsEdit = (props) => (
  <Edit title={<AdditionalItemsTitle />} {...props}>
    <TabbedForm>
      <FormTab label={'resources.items.tabs.main'}>
        <TextInput
          label="Производитель"
          source="manufacturer"
          validate={[required()]}
        />
        <TextInput
          source="category"
          label="Категория"
          validate={[required()]}
        />
        <TextInput source="name" label="Наименование" validate={[required()]} />
        <NumberInput
          source="optPrice"
          label="Цена, опт."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NumberInput
          source="price"
          label="Цена, розн."
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString()}
          initialValue={0}
          validate={[required()]}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <NumberInput
          source="order"
          label="Порядок"
          min={1}
          max={99}
          step={1}
          validate={[required()]}
        />
        <Divider />
        <p>Склад</p>
        <NumberInput
          source="stock1"
          label="Наличие Одесса"
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
          min={0}
          step={1}
          initialValue={0}
          validate={[required()]}
        />
        <NumberInput
          source="stock2"
          label="Наличие Украина"
          format={(v) => parseFloat(v)}
          parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
          min={0}
          step={1}
          initialValue={0}
          validate={[required()]}
        />
      </FormTab>
      <FormTab label="resources.items.tabs.details" path="details">
        <TextInput
          fullWidth
          source="power"
          label="Мощность охл. / обогр. (кВт)"
        />
        <TextInput
          fullWidth
          source="powerСonsumption"
          label="Потребляемая мощность охл. / обогр. (кВт)"
        />
        <TextInput
          fullWidth
          source="temp"
          label="Диапазон внешн. температур (°С)"
        />
        <TextInput fullWidth source="mark" label="Тип компрессора" />
        <TextInput
          fullWidth
          source="dimensions"
          label="Габариты вн. / нар. блок (мм)"
        />
        <TextInput
          fullWidth
          source="connectionWires"
          label="Межблочный кабель (мм²)"
        />
        <TextInput
          fullWidth
          source="connectionPipelines"
          label="Диаметр труб (мм)"
        />
      </FormTab>
      <FormTab label="resources.items.tabs.photo" path="photo">
        <ImageField source="image" label="Текущее Фото" />
        <ImageInput
          source="image"
          label="Новое Фото"
          accept="image/*"
          multiple={false}
        >
          <ImageField source="image" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const AdditionalItemsCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label={'resources.items.tabs.main'}>
          <TextInput
            label="Производитель"
            source="manufacturer"
            validate={[required()]}
          />
          <TextInput
            source="category"
            label="Категория"
            validate={[required()]}
          />
          <TextInput
            source="name"
            label="Наименование"
            validate={[required()]}
          />
          <NumberInput
            source="optPrice"
            label="Цена, опт."
            format={(v) => parseFloat(v)}
            parse={(v) => v.replace(/,/g, '.').toString()}
            initialValue={0}
            validate={[required()]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="price"
            label="Цена, розн."
            format={(v) => parseFloat(v)}
            parse={(v) => v.replace(/,/g, '.').toString()}
            initialValue={0}
            validate={[required()]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="order"
            label="Порядок"
            min={1}
            max={99}
            step={1}
            initialValue={99}
            validate={[required()]}
          />
          <Divider />
          <p>Склад</p>
          <NumberInput
            source="stock1"
            label="Наличие Одесса"
            format={(v) => parseFloat(v)}
            parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
            min={0}
            step={1}
            initialValue={0}
            validate={[required()]}
          />
          <NumberInput
            source="stock2"
            label="Наличие Украина"
            format={(v) => parseFloat(v)}
            parse={(v) => v.replace(/,/g, '.').toString().split('.')[0]}
            min={0}
            step={1}
            initialValue={0}
            validate={[required()]}
          />
        </FormTab>
        <FormTab label="resources.items.tabs.details" path="details">
          <TextInput fullWidth source="power" label="Мощность охл. / обогр." />
          <TextInput
            fullWidth
            source="powerСonsumption"
            label="Потребляемая мощность охл. / обогр. (кВт)"
          />
          <TextInput
            fullWidth
            source="temp"
            label="Диапазон внешн. температур"
          />
          <TextInput fullWidth source="mark" label="Марка компрессора" />
          <TextInput
            fullWidth
            source="dimensions"
            label="Габариты вн. / нар. блок"
          />
          <TextInput
            fullWidth
            source="connectionWires"
            label="Подключение проводов"
          />
          <TextInput
            fullWidth
            source="connectionPipelines"
            label="Подключение трубопроводов"
          />
        </FormTab>
        <FormTab label="resources.items.tabs.photo" path="photo">
          <ImageInput
            source="image"
            label="Фото"
            accept="image/*"
            multiple={false}
          >
            <ImageField source="image" />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
