import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  ImageField,
  ImageInput,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

import Divider from '@material-ui/core/Divider';
import MobileGrid from './MobileGrid';
import React from 'react';
import { useMediaQuery } from '@material-ui/core';

const NotificationsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
  </Filter>
);

export const NotificationsList = (props) => {
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  return (
    <List
      filters={<NotificationsFilter />}
      {...props}
      title={props.options.label}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="name" label="Заголовок" />
          <ImageField source="image" label="Фото" className="series_image" />
          <DateField source="date" label="Дата изменения" showTime />
        </Datagrid>
      )}
    </List>
  );
};

const NotificationsTitle = ({ record }) => {
  return <span>Push уведомление {record ? `"${record.name}"` : ''}</span>;
};

export const NotificationsEdit = (props) => (
  <Edit title={<NotificationsTitle />} {...props}>
    <SimpleForm>
      <TextInput
        source="notificationTitle"
        label="Заголовок push увед."
        validate={[required()]}
        disabled
        inputProps={{
          maxLength: 30,
        }}
      />
      <TextInput
        source="notificationText"
        label="Подзаголовок push увед."
        validate={[required()]}
        disabled
        inputProps={{
          maxLength: 40,
        }}
      />
      <ImageField source="image" label="Текущее Фото" />
      <ImageInput
        source="image"
        label="Новое Фото"
        accept="image/*"
        multiple={false}
      >
        <ImageField source="image" />
      </ImageInput>
      <TextInput source="name" label="Заголовок" validate={[required()]} />
      <TextInput
        source="text"
        label="Текст"
        validate={[required()]}
        multiline
        fullWidth
      />
      <BooleanInput label="Сохранить" source="saved" />
      {/* <RichTextInput source="body" label="Содержимое" validate={[required()]} /> */}
      <ReferenceInput
        label="Получатель"
        source="sendTo"
        reference="customers"
        perPage={1000}
      >
        <SelectInput
          optionText={(customer) =>
            `${customer.name} ${customer?.companyName ?? ''} (${
              customer.phone
            })`
          }
          resettable
          disabled={true}
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const NotificationsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="notificationTitle"
        label="Заголовок push увед."
        validate={[required()]}
        inputProps={{
          maxLength: 30,
        }}
      />
      <TextInput
        source="notificationText"
        label="Подзаголовок push увед."
        validate={[required()]}
        inputProps={{
          maxLength: 40,
        }}
      />
      <ImageInput
        source="image"
        label="Фото"
        accept="image/*"
        multiple={false}
        validate={[required()]}
      >
        <ImageField source="image" />
      </ImageInput>
      <TextInput source="name" label="Заголовок" validate={[required()]} />
      <TextInput
        source="text"
        label="Текст"
        validate={[required()]}
        multiline
        fullWidth
      />
      <BooleanInput label="Сохранить" source="saved" />
      {/* <RichTextInput source="body" label="Содержимое" validate={[required()]} /> */}
      <Divider />
      <p>Оставьте это поле пустым для отправки всем пользователям</p>
      <ReferenceInput
        label="Получатель"
        source="sendTo"
        reference="customers"
        perPage={1000}
      >
        <SelectInput
          optionText={(customer) =>
            `${customer.name} ${customer?.companyName ?? ''} (${
              customer.phone
            })`
          }
          resettable
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
