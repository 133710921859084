import {
  Create,
  Datagrid,
  DateField,
  Edit,
  FileField,
  FileInput,
  List,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';

import React from 'react';

export const CatalogsPdfList = (props) => (
  <List {...props} title={props.options.label}>
    <Datagrid rowClick="edit">
      <TextField source="name" label="Название файла" />
      <DateField source="date" label="Дата добавления" />
    </Datagrid>
  </List>
);

const CatalogsPdfTitle = ({ record }) => {
  return <span>PDF каталог {record ? `"${record.name}"` : ''}</span>;
};

export const CatalogsPdfEdit = (props) => (
  <Edit title={<CatalogsPdfTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" label="Название файла" validate={[required()]} />
      <FileInput
        source="image"
        label="Файл PDF"
        accept="application/pdf"
        validate={[required()]}
      >
        <FileField source="image" />
      </FileInput>
    </SimpleForm>
  </Edit>
);

export const CatalogsPdfCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Название файла" validate={[required()]} />
      <FileInput
        source="image"
        label="Файл PDF"
        accept="application/pdf"
        validate={[required()]}
      >
        <FileField source="image" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);
