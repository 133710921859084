import {
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  Filter,
  FormDataConsumer,
  Labeled,
  List,
  PasswordInput,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  maxLength,
  minLength,
  required,
} from 'react-admin';

import MobileGrid from './MobileGrid';
import PhoneField from './PhoneField';
import React from 'react';
import { useMediaQuery } from '@material-ui/core';

const validatePassword = [minLength(6)];

const CustomerFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск" source="q" alwaysOn />
  </Filter>
);

export const CustomerList = (props) => {
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <List
      {...props}
      title={props.options.label}
      sort={{ field: 'signUpDate', order: 'DESC' }}
      filters={isSmall ? <CustomerFilter /> : undefined}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="name" label="Имя" />
          <TextField source="email" label="Email" />
          <TextField source="companyName" label="Название компании" />
          <PhoneField source="phone" label="Телефон" />
          <SelectField
            label="Категория цен"
            source="role"
            choices={[
              { id: 'admin', name: 'Админ' },
              { id: 'user', name: 'Розница' },
              { id: 'opt', name: 'Опт' },
            ]}
          />
          <DateField source="signUpDate" label="Дата регистрации" />
        </Datagrid>
      )}
    </List>
  );
};

const CustomerTitle = ({ record }) => {
  return <span>Клиент {record ? `"${record.name}"` : ''}</span>;
};

export const CustomerEdit = (props) => (
  <Edit title={<CustomerTitle />} {...props}>
    <SimpleForm>
      <TextInput label="Имя" source="name" validate={[required()]} />
      <TextInput label="Email" source="email" />
      <TextInput label="Название компании" source="companyName" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const choices = [
            { id: 'user', name: 'Розница' },
            { id: 'opt', name: 'Опт' },
          ];
          if (formData.role === 'admin') {
            choices.push({ id: 'admin', name: 'Админ' });
          }
          return (
            <SelectInput
              disabled={formData.role === 'admin'}
              label="Категория цен"
              source="role"
              choices={choices}
            />
          );
        }}
      </FormDataConsumer>
      <TextInput
        label="Номер телефона"
        source="phone"
        validate={[required(), maxLength(10), minLength(10)]}
        inputProps={{
          maxLength: 10,
        }}
      />
      <BooleanInput label="Изменить пароль" source="chPass" />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData, ...rest }) =>
          formData.chPass && (
            <Labeled
              label={`Введите новый пароль, затем отправьте его клиенту вручную. Пароль будет доступен для просмотра только 1 раз`}
            >
              <PasswordInput
                label="Введите новый пароль"
                source="password"
                initiallyVisible
                disabled={!formData.chPass}
                validate={validatePassword}
              />
            </Labeled>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
